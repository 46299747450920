document.addEventListener('DOMContentLoaded', function () {
    var endpointUrl = $('#filterContainer').data('endpoint-url');

    makeTableRowsLinks();

    (function attachListenersToSortDropdown() {
        var sortDropdown = $('#sortBy');

        if (sortDropdown) {
            sortDropdown.on('change', function () {
                getBookingResults();
            });
        }
    })();

    (function dateRangePicker() {
        var rangePicker = $('#dateRange');

        var startDate = $('#dateRangeInitialStartDate').val();
        var endDate = $('#dateRangeInitialEndDate').val();

        if (rangePicker) {
            rangePicker.daterangepicker({
                "parentEl": ".filter-selection",
                "locale": {
                    "format": "DD/MM/YYYY"
                },
                "buttonClasses": "btn dp-btn",
                "applyButtonClasses": "btn-primary",
                "cancelClass": "btn-secondary",
                "startDate": startDate,
                "endDate": endDate
            });

            rangePicker.on('apply.daterangepicker', function (ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
                $('#filterFromDate').val(picker.startDate.toISOString());
                $('#filterToDate').val(picker.endDate.toISOString());
            });
        }
    })();

    (function showFilterMenu() {
        var filterMenu = $('.filter-selection-wrapper');

        if (filterMenu !== null) {
            $(document).on('click', function (e) {
                openFilterMenu(e);
            });
        }

        function openFilterMenu(e) {
            if ($(e.target).closest('.select-filter').length > 0) {
                filterMenu.addClass('d-block');
            } else if (!($(e.target).closest('.filter-selection').length > 0) && !($(e.target).closest('.daterangepicker').length > 0) && !($(e.target).closest('th.available').length > 0)) {
                filterMenu.removeClass('d-block');
            }
        }
    })();

    (function attachListenersToRadios() {
        var dateRangePickerWrapper = document.querySelector('.dateRange-picker-wrapper');

        if (dateRangePickerWrapper !== null) {

            var filterLastUpdatedRadios = document.querySelectorAll('input[type="radio"]');
            var filterDateRange = document.getElementById('customDateRange');
            var fromDate = $('#filterFromDate'), toDate = $('#filterToDate');

            for (var i = 0; i < filterLastUpdatedRadios.length; i++) {
                filterLastUpdatedRadios[i].addEventListener('change', function () {
                    showDatePicker();
                });
            }
        }

        function showDatePicker() {
            var originalDateRangeStr = $('#dateRange').val();
            var dateArray = originalDateRangeStr.split(" - ");

            if (filterDateRange.checked) {
                dateRangePickerWrapper.classList.add('d-block');
                fromDate.prop('disabled', false).val(dateArray[0]);
                toDate.prop('disabled', false).val(dateArray[1]);
            } else {
                dateRangePickerWrapper.classList.remove('d-block');
                fromDate.prop('disabled', true).val('');
                toDate.prop('disabled', true).val('');
            }
        }

    })();

    (function filterResults() {
        var searchButton = $('#searchButton');

        if (searchButton.length > 0) {
            searchButton.on('click', function () {
                $('.filter-selection-wrapper').addClass('d-none').removeClass('d-block');
                getBookingResults();
            });
        }
    })();

    (function exportResults() {
        var exportButton = $('#exportButton');


        if (exportButton.length > 0) {
            exportButton.on('click', function () {
                getExportUrl(this);
            });
        }
    })();

    function makeTableRowsLinks() {
        var brokerPortalTableRow = document.querySelectorAll('.broker-portal-table-block tbody tr[data-booking-ref]');

        if (brokerPortalTableRow.length > 0) {
            for (var i = 0; i < brokerPortalTableRow.length; i++) {
                brokerPortalTableRow[i].addEventListener('click', function () {
                    window.location = window.location.href + '/details?b=' + this.getAttribute('data-booking-ref');
                    return false;
                });
            }
        }
    }

    function getBookingResults() {
        $.ajax({
            url: endpointUrl + "FilteredTableResults",
            data: mapFilterViewModel()
        })
            .done(function (result) {
                $('#resultsTable').empty();
                var tableContent = result.length > 0
                    ? result
                    : $('<p/>', {
                        'title' : 'No results to display based on selected filter',
                        'class' : 'text-center mt-3'
                    });

                $('#resultsTable').append(tableContent);
                makeTableRowsLinks();
            })
            .fail(function (err) {
                console.log('Error: ' + err.status);
            });
    }

    function getExportUrl(elem) {
        $(elem).attr('href', $(elem).attr('href') + '?' + $.param(mapFilterViewModel()));
    }

    function mapFilterViewModel() {
        return {
            'ShowBrokerReferrals': $('#filterBrokerReferrals').length > 0 ? $('#filterBrokerReferrals').prop('checked') : false,
            'ShowAdasReferrals': $('#filterAdasReferrals').length > 0 ? $('#filterAdasReferrals').prop('checked') : false,
            'Filter.SortBy': $('#sortBy').val(),
            'Filter.DateRange': $('input[name="Filter.DateRange"]:checked').val(),
            'Filter.FromDate': $('#filterFromDate').val(),
            'Filter.ToDate': $('#filterToDate').val(),
            'Filter.IsCompleted': $('#filterIsCompleted').prop('checked'),
            'Filter.IsOpen': $('#filterIsOpen').prop('checked'),
            'Filter.IsCancelled': $('#filterIsCancelled').prop('checked')
        };
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
});


