$(document).ready(function () {

    $('.footer-header-form #submitCallBack').on('click', function () {
        var nameInput = $('.footer-header-form #Name').val();
        var phoneNumberInput = $('.footer-header-form #PhoneNumber').val();
        var regexPhoneNumber = /^(\+)?(\d)*$/;
        var testPhoneNumber = regexPhoneNumber.test(phoneNumberInput);
        var isPhoneValid = !!phoneNumberInput && testPhoneNumber;
        var postData = {
            Name: nameInput,
            PhoneNumber: phoneNumberInput
        };

        if (!!nameInput && isPhoneValid) {
            $.post(
                "/Umbraco/Api/RequestCallbackApi/Send",
                postData,
                function (data) {
                    if (data) {
                        var status = data.Status;
                        if (status === "OK") {
                            var msgContent = '<p>Your message has been successfully submitted, Thank you</p>';
                            $('#notification-msg-container .success-msg').removeClass('d-none').find('.msg').html(msgContent);
                        } else {
                            var msgContent =
                                '<p>Oops there was an error, try again or try to contact us:</p><p>Call us 0800 182 2371</p><p><a href="https://geoffreyinsurance.whoson.com/newchat/chat.aspx?domain=www.autowindscreens.co.uk&amp;timestamp=1516116989306&amp;session=572-1514990309643"><i class="awicon_chat"></i> Start Live chat</a></p>';
                            $('#notification-msg-container .danger-msg').removeClass('d-none').find('.msg').html(msgContent);
                        }
                    } else {
                        var msgContent =
                            '<p>Oops there was an error, try again or try to contact us:</p><p>Call us 0800 182 2371</p><p><a href="https://geoffreyinsurance.whoson.com/newchat/chat.aspx?domain=www.autowindscreens.co.uk&amp;timestamp=1516116989306&amp;session=572-1514990309643"><i class="awicon_chat"></i> Start Live chat</a></p>';
                        $('#notification-msg-container .danger-msg').removeClass('d-none').find('.msg').html(msgContent);
                    }
                }
            );
        } else if (!nameInput) {
            $('.footer-header-form #Name').addClass('input-validation-error');
        } else if (!isPhoneValid) {
            $('.footer-header-form #PhoneNumber').addClass('input-validation-error');
        }
    });
});