/* Grab all the links contain the whoson.com domain, add a query string and open a popup */
$(document).ready(function () {
    $("a[href*='whoson.com']").each(function (idx, elem) {
        var baseUrl = $(elem).attr("href");
        var domain = "domain=www.autowindscreens.co.uk";
        var timestamp = "timestamp=" + Date.now().toString();
        var session = "session=572-1514990309643";
        $(elem).attr("href", baseUrl + "?" + domain + "&" + timestamp + "&" + session);

        $(elem).click(function () {
            var href = $(elem).attr("href");
            window.open(href, "Chat", "width=530,height=540");
            return false;
        });
    });
});
