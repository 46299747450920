$(document).ready(function () {

    if (!$('.social-share-block').length) {
        return false;
    }

    var jsSocialContainer = $(".social-share-block .jssocials-container");

    jsSocialContainer.jsSocials({
        url: window.location.href,
        showLabel: false,
        showCount: false,
        shares: ["facebook", "twitter", "pocket", "messenger"]
    });

    //To change icons
    jsSocialContainer.jsSocials("shareOption", "twitter", "logo", "awicon_twitter");
    jsSocialContainer.jsSocials("shareOption", "facebook", "logo", "awicon_facebook");
    jsSocialContainer.jsSocials("shareOption", "pocket", "logo", "awicon_pocket");
    jsSocialContainer.jsSocials("shareOption", "messenger", "logo", "awicon_messenger");
})
