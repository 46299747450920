$(document).ajaxError(function (event, request, settings) {
    var msgContent = '<p>Oops there was an error, try again or try to contact us:</p><p>Call us 0800 182 2371</p><p><a href="https://geoffreyinsurance.whoson.com/newchat/chat.aspx?domain=www.autowindscreens.co.uk&amp;timestamp=1516116989306&amp;session=572-1514990309643"><i class="awicon_chat"></i> Start Live chat</a></p>'
    $('#notification-msg-container .danger-msg').removeClass('d-none').find('.msg').html(msgContent);
    $('.loading-appointment-calendar').addClass('d-none');
});

var ajaxBtn;

$(document).ajaxSend(function (event, request, settings) {
    ajaxBtn = this.activeElement;
    $(ajaxBtn).prop('disabled', true);
});

$(document).ajaxComplete(function () {
    $(ajaxBtn).prop('disabled', false);
    ajaxBtn = null;
});