//FUNCTION TO GET AND AUTO PLAY YOUTUBE/Vimeo VIDEO FROM DATATAG
function autoPlayEmbededVideoModal() {
    var trigger = $('.videoModalTriger');
    trigger.on('click' ,function () {
        var theModal = $(this).data("target");
        var videoSRC = $(this).attr("data-videoModal");
        var videoSRCauto = videoSRC + "?autoplay=1";
        $(theModal + ' iframe').attr('src', videoSRCauto);
        $(theModal).on('hidden.bs.modal', function (e) {
            $(theModal + ' iframe').attr('src', '');
        });
    });
};

$(document).ready(function () {
    autoPlayEmbededVideoModal();
});