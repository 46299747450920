$(document).ready(function () {
	if (!$('.tips-card').length) {
		return false;
	} else {
		setReadmoreLinks();
	}

	$(window).resize(function () {
		// prevent resizing from mobile > desktop height bug
		setReadmoreLinks();

		// set any expanded content containers height to auto to replace the collapsed height inline setting
		$('.tips-card--text.expanded').css('height', 'auto');
	});

	$('.tips-card .cta--read-more').click(function () {
		var $link;
		var $paragraphs;
		var $container;
		var $totalHeight;

		totalHeight = 0

		$link = $(this);
		$card = $(this).closest('.tips-card');
        $container = $(this).closest('.tips-card').find('.tips-card--text');
		$paragraphs = $container.find("p");

        // measure how tall inside should be by adding together heights of all inside paragraphs
		$paragraphs.each(function () {
            totalHeight += $(this).outerHeight();
        });

		// add some buffer to the height
		totalHeight += 48;

		$container
            .css({
                // Set height to prevent instant jumpdown when max height is removed
				'height': $container.height(),
				'max-height': 9999
			})
            .animate({
                'height': totalHeight
            });
		
		// fade out the read more link
		$link.fadeTo(500, 0, function () {
			$link.css('visibility', 'hidden');
		});

		// remove the fadeout gradient from the container
		// add expanded class so resize setReadmoreLinks() call doesn't touch it
		$container.removeClass('fadeout');
		$link.removeClass('shown');

		$container.addClass('expanded');

        // prevent jump-down
        return false;
	});

	function setReadmoreLinks() {
		$container = $('.tips-card').find('.tips-card--text:not(.expanded)');

		$($container).each(function () {
			totalHeight = 0;

			$paragraphs = $(this).find('p');

			$paragraphs.each(function () {
				totalHeight += $(this).outerHeight();
			});

			// check for minimum of 6 lines of content
			if (totalHeight >= 149) {
				$(this).closest('.tips-card--text').addClass('fadeout');
				$(this).closest('.tips-card').find('.read-more').addClass('shown');
			}
			else {
				$(this).closest('.tips-card--text').removeClass('fadeout');
				$(this).closest('.tips-card').find('.read-more').removeClass('shown');
			}
		})
	}
});