$(document).ready(function () {

    $('.competition-form').submit(function (e) {

        var formIsValid = $(this).valid();

        if (formIsValid) {
            var $firstNameInput = $('.competition-form #Firstname');
            var $emailInput = $('.competition-form #Email');
            var $telephoneNumberInput = $('.competition-form #TelephoneNumber');
            var $postcodeInput = $('.competition-form #Postcode');
            var $premiershipClubInput = $('.competition-form #PremiershipClub');
            var $termsAndConditions = $('.competition-form #TermsAndConditions');
            var $competitionPageId = $('#CompetitionPageId')
            var $competitionName = $('#CompetitionName')

            $.post(
                "/Umbraco/Api/Competition/Send",
                {
                    Firstname: $firstNameInput.val(),
                    Email: $emailInput.val(),
                    TelephoneNumber: $telephoneNumberInput.val(),
                    Postcode: $postcodeInput.val(),
                    PremiershipClub: $premiershipClubInput.val(),
                    TermsAndConditions: $termsAndConditions.is(':checked'),
                    CompetitionPageId: $competitionPageId.val(),
                    CompetitionName: $competitionName.val(),
                },
                function (data) {
                    if (data && data.Status === 'OK') {
                        $('#notification-msg-container .success-msg').removeClass('d-none').find('.msg').html('<p>Your entry has been successfully submitted, Thank you</p>');

                        $firstNameInput.val('');
                        $emailInput.val('');
                        $telephoneNumberInput.val('');
                        $postcodeInput.val('');
                        $premiershipClubInput.val('');
                        $termsAndConditions.prop('checked', false);

                    } else {
                        var errorMsg = '<p>Oops there was an error, try again or try to contact us:</p><p>Call us 0800 182 2371</p><p>' +
                            '<a href="https://geoffreyinsurance.whoson.com/newchat/chat.aspx?domain=www.autowindscreens.co.uk&amp;timestamp=1516116989306&amp;session=572-1514990309643">' +
                            '<i class="awicon_chat"></i> Start Live chat</a></p>';

                        $('#notification-msg-container .danger-msg').removeClass('d-none').find('.msg').html(errorMsg);
                    }
                }
            );
        }

        return false;
    });
});