$(document).ready(function () {
    if (!$('.accordion').length) {
        return false;
    }

    $('.accordion .panel.panel-light').on('click', '.collapse-cta', function () {
        if ($(this).hasClass('collapsed')) {
            $(this).closest('.accordion').find('.panel-default').addClass('panel-light');
            $(this).closest('.accordion').find('.panel-default.panel-light').removeClass('panel-default');
            $(this).closest('.panel').removeClass('panel-light');
            $(this).closest('.panel').addClass('panel-default');
        } else {
            $(this).closest('.panel').addClass('panel-light');
            $(this).closest('.panel').removeClass('panel-default');
        }
    });
})