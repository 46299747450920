$(document).ready(function () {
	
	$("#FormContactUs")
		.validate({
			errorElement: "span",
			errorClass: "error",
			rules: {
				"FormContactUs.Firstname": { required: true, maxlength: 50 },
				"FormContactUs.Lastname": { required: true, maxlength: 50 },
				"FormContactUs.EmailAddress": { required: true, email: true, maxlength: 50 },
				"FormContactUs.TelephoneNumber": { check_telephone_number: true, required: true, maxlength: 15, minlength: 10 },
				"FormContactUs.Comments": { required: true, maxlength: 1000 }

			},
			messages: {
				"FormContactUs.Name": { required: "Please enter your first name", maxlength: "Max length exceeded" },
				"FormContactUs.Lastname": { required: "Please enter your last name", maxlength: "Max length exceeded" },
				"FormContactUs.EmailAddress": { required: "Please enter your email address", maxlength: "Max length exceeded" },
				"FormContactUs.TelephoneNumber": { required: "Please enter your telephone number", maxlength: "Max length exceeded", minlength: "Min length not reached" },
				"FormContactUs.Comments": { required: "Please provide details of your request", maxlength: "Max length exceeded" }
			}
		});
});

//$(document).ready(function () {
//    $(".contact-form").submit(function (e) {
//        e.preventDefault();
//        if ($(this).valid()) {
//            var postData = $(this).serializeArray();
//            $(this).find('.spinner').addClass('spinner-active');
//            $.post(
//                $(this).attr('action'),
//                postData,
//                function (data) {
//                    if (data) {
//                        var status = data.Status;
//                        if (status == "OK") {
//                            $(this).find('.spinner').removeClass('spinner-active');
//                            $(".contact-form").closest(".collapse.show").collapse('hide');
//                            var msgContent = '<p>Your message has been successfully submitted, Thank you</p>';
//                            $('#notification-msg-container .success-msg').removeClass('d-none').find('.msg').html(msgContent);
//                        } else {
//                            $(this).find('.spinner').removeClass('spinner-active');
//                            var msgContent = '<p>Oops there was an error, try again or try to contact us:</p><p>Call us 0800 999 5000</p><p><a href="https://geoffreyinsurance.whoson.com/newchat/chat.aspx?domain=www.autowindscreens.co.uk&amp;timestamp=1516116989306&amp;session=572-1514990309643"><i class="awicon_chat"></i> Start Live chat</a></p>';
//                            $('#notification-msg-container .danger-msg').removeClass('d-none').find('.msg').html(msgContent);
//                        }
//                    } else {
//                        $(this).find('.spinner').removeClass('spinner-active');
//                        var msgContent = '<p>Oops there was an error, try again or try to contact us:</p><p>Call us 0800 999 5000</p><p><a href="https://geoffreyinsurance.whoson.com/newchat/chat.aspx?domain=www.autowindscreens.co.uk&amp;timestamp=1516116989306&amp;session=572-1514990309643"><i class="awicon_chat"></i> Start Live chat</a></p>';
//                        $('#notification-msg-container .danger-msg').removeClass('d-none').find('.msg').html(msgContent);
//                    }
//                }
//            );
//        }
//    })
//});
