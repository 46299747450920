$(document).ready(function () {
    $('.page-header-block').on('click', 'button.burger', function () {
        $(this).toggleClass('active');
        $(this).closest('.page-header-block').find('.main-navigation.mobile').toggleClass('active');
        $('body').toggleClass('modal-open');
    });

    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.page-header-block').outerHeight();
    var insurer = false;
    var queryStr = "insurance?insurer=";

    if (window.location.href.toLowerCase().indexOf(queryStr) >= 0) {
        insurer = true;
    }

    if (!(insurer)) {
        $(window).scroll(function (event) {
            didScroll = true;
        });
    }

    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if (Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            $('.page-header-block').addClass('nav-up');
            $('body').addClass('nav-is-up');
        } else {
            // Scroll Up
            if (st + $(window).height() < $(document).height()) {
                $('.page-header-block').removeClass('nav-up');
                $('body').removeClass('nav-is-up');
            }
        }

        lastScrollTop = st;
    }
});