$(document).ready(function () {
    $('#print-insurance-form-thank-you').on('click', printInsuranceFormThankYou);
});

function printInsuranceFormThankYou(e) {
    var logoAutoWindscreen = $('.logo-block img ').attr('src');
    var isiOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (isiOS) {
        $("#contentToPrint").print({
            globalStyles: true,
            mediaPrint: false,
            stylesheet: null,
            noPrintSelector: ".no-print",
            iframe: true,
            append: null,
            prepend: '<img src="' + logoAutoWindscreen + '" style="width: 200px; margin: 0px 0px 0px 0px">',
            manuallyCopyFormValues: true,
            deferred: $.Deferred(),
            timeout: 750,
            title: null,
            doctype: '<!doctype html>'
        });
    } else {
        $("#contentToPrint").print({
            globalStyles: true,
            mediaPrint: false,
            stylesheet: null,
            noPrintSelector: ".no-print",
            iframe: false,
            append: null,
            prepend: '<img src="' + logoAutoWindscreen + '" style="width: 200px; margin: 0px 0px 0px 0px">',
            manuallyCopyFormValues: true,
            deferred: $.Deferred(),
            timeout: 750,
            title: null,
            doctype: '<!doctype html>'
        });
    }
}