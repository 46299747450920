// media query change
function addDestroyCarouselWidthChange(mq) {
    var carousel = $('.mobile-carousel');
    if (!mq.matches) {
        carousel.owlCarousel({
            loop: false,
            margin: 0,
            nav: true,
            items: 1,
            navText: ['<i class="awicon_chevron-left"></i>', '<i class="awicon_chevron-right"></i>']
        });
    }

    else {
        carousel.css('display', 'flex');
        carousel.trigger('destroy.owl.carousel', 0);
    }
}

$(document).ready(function () {
    // media query event handler
    if (matchMedia) {
        var mq = window.matchMedia("(min-width: 768px)");
        mq.addListener(addDestroyCarouselWidthChange);
        addDestroyCarouselWidthChange(mq);
    };
});