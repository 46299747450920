$(document).ready(function () {
    //$('form').on('keyup keypress', function (e) {
    //    var keyCode = e.keyCode || e.which;
    //    if (keyCode === 13) {
    //        e.preventDefault();
    //        return false;
    //    }
    //});

    var formId = $('.form-one form').first().attr('id');
    $('.mobile-progress-tabs-footer .after').attr('form', formId);

    $(".auto-tab").keyup(function () {
        if (this.value.length == this.maxLength) {
            $(this).next('.auto-tab').focus();
        }
    });

    $('.contact-form').on('change', '.form-picker', function () {
        var formPicker = $(this);
        var formWrapper = formPicker.closest('form').find('.form-group-picker');
        var getValue = this.value;
        if (getValue == "") {
            // close the opened form
            formPicker.closest('form').find('.form-group-picker .form-group-wraper').removeClass('active');
        } else {
            var formToShow = $('#' + getValue);
            if (!formWrapper.hasClass('active')) {
                formWrapper.addClass('active');
                formToShow.addClass('active');
            }
            else {
                formPicker.closest('form').find('.form-group-picker .form-group-wraper').removeClass('active');
                formToShow.addClass('active');
            }
        }
        
    });

    $('.date-picker').on('keydown', 'input[type="number"]', function (e) {
        -1 !== $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) ||
            (/65|67|86|88/.test(e.keyCode) && (e.ctrlKey === true || e.metaKey === true)) &&
            (!0 === e.ctrlKey || !0 === e.metaKey) ||
            35 <= e.keyCode && 40 >= e.keyCode ||
            (e.shiftKey || 48 > e.keyCode || 57 < e.keyCode) &&
            (96 > e.keyCode || 105 < e.keyCode) &&
            e.preventDefault();
    });


    $('input[type="number"]').on("input", function (e) {
        var currentInput = $(this);
        var maxLength = currentInput.attr('maxlength');

        if (this.value.length > maxLength) {
            this.value = this.value.slice(0, this.maxLength);
        }
    });


    var updateMinDate = function(policyStart) {
        $(".jDamageDate").attr('min', policyStart.attr('value'));
    };



    $('.date-picker').on('change', function () {
        var currentInput = $(this);
        var day = currentInput.find('.date-picker--day').val();
        var month = currentInput.find('.date-picker--month').val();
        var year = currentInput.find('.date-picker--year').val();
        var invisibleDateInput = currentInput.find('.date-picker--date');
        var invisibleDateInputMin = invisibleDateInput.attr('min');
        var invisibleDateInputMax = invisibleDateInput.attr('max');

        // correct format for date input
        if (month.length < 2) {
            month = "0" + month;
        };
        if (day.length < 2) {
            day = "0" + day;
        }

        ////Needed if no min or max
        switch (year.length) {
            case 1:
                year = "000" + year;
                break;
            case 2:
                year = "00" + year;
                break;
            case 3:
                year = "0" + year;
                break;
        }

        var theDate = year + "-" + month + "-" + day;

        invisibleDateInput.val(theDate);

        if (currentInput.hasClass("jStartDate")) {
            updateMinDate(invisibleDateInput);
        }

        if (invisibleDateInput.attr('value') !== theDate) {
            currentInput.closest('.form-group').addClass('error');
        }
        else {
            currentInput.closest('.form-group').removeClass('error');
            if (typeof invisibleDateInputMin !== typeof undefined && invisibleDateInputMin !== false) {
                if (invisibleDateInputMin > theDate) {
                    currentInput.closest('.form-group').addClass('error');
                }
            }

            if (typeof invisibleDateInputMax !== typeof undefined && invisibleDateInputMax !== false) {
                if (invisibleDateInputMax < theDate) {
                    currentInput.closest('.form-group').addClass('error');
                }
            }
        }
    });

    // extend jquery range validator to work for required checkboxes
    var defaultRangeValidator = $.validator.methods.range;
    $.validator.methods.range = function(value, element, param) {
        if(element.type === 'checkbox') {
            // if it's a checkbox return true if it is checked
            return element.checked;
        } else {
            // otherwise run the default validation function
            return defaultRangeValidator.call(this, value, element, param);
        }
    }
});
