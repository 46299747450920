(function () {
    document.addEventListener("DOMContentLoaded", function (event) {
        var hubMapContainers = document.querySelectorAll('.map-container');

        Array.prototype.forEach.call(hubMapContainers, function (el, i) {
            var CurrentHubMapContainer = hubMapContainers[i];

            $.ajax({
                type: 'GET',
                url: '/umbraco/Api/HubApi/GetHubs',
                dataType: 'json',
                success: function (data) {
                    var hubs = data.Hubs;
                    var closeHubInfosCta = document.querySelector(".close-hub-infos");
                    initMap(CurrentHubMapContainer, hubs);

                    closeHubInfosCta.addEventListener("click", function () {
                        closeHubInfos(closeHubInfosCta);
                    });
                }
            });
        });
    });

    function initMap(element, hubs) {
        var hubMapContainer = element;
        var mapSelector = hubMapContainer.querySelector('#googleMapHubs');
        var locCenterLat = 54.00366;
        var locCenterLng = -1.5500437;
        var locZoom = 6;
        var locCenter = { lat: locCenterLat, lng: locCenterLng };
        var markers = [];
        var currentMarker;

        var iconMarker = {
            url: "/Content/images/svg-icons/map_marker.png",
            scaledSize: new google.maps.Size(21, 32),
            size: new google.maps.Size(21, 32),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(21, 32)
        };

        var iconMarkerHover = {
            url: "/Content/images/svg-icons/map_marker2.png",
            scaledSize: new google.maps.Size(21, 32),
            size: new google.maps.Size(21, 32),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(21, 32)
        };

        var map = new google.maps.Map(mapSelector, {
            zoom: locZoom,
            styles: mapStyle,
            backgroundColor: 'none',
            mapTypeControl: false,
            streetViewControl: false,
            center: locCenter,
            fullscreenControl: false
        });

        if (hubs.length > 0) {
            hubs.forEach(function (item, index) {
                var currentLoc = { lat: parseFloat(item.Latitude), lng: parseFloat(item.Longitude) };
                var marker = new google.maps.Marker({
                    position: currentLoc,
                    clickable: true,
                    zIndex: 99,
                    optimized: false,
                    icon: iconMarker,
                    title: item.Name,
                    map: map
                });

                var hubId = item.Name;

                google.maps.event.addListener(marker, 'click', function () {
                    markers.forEach(function (item) {
                        item.setIcon(iconMarker);
                    });
                    marker.setIcon(iconMarkerHover);
                    showHubInfo(hubMapContainer, hubId);
                    currentMarker = marker;
                });

                google.maps.event.addListener(marker, 'mouseover', function () {
                    if (currentMarker !== marker) {
                        marker.setIcon(iconMarkerHover);
                    }
                });

                google.maps.event.addListener(marker, 'mouseout', function () {
                    if (currentMarker !== marker) {
                        marker.setIcon(iconMarker);
                    }
                });

                markers.push(marker);
            });
        }
    }

    function showHubInfo(element, hubId) {
        var hubMapContainer = element;
        var hubInfosContainer = document.querySelector('.hub-infos');
        var hubInfoTemplate = document.querySelector('.hub-info');
        var spinner = document.querySelector('.spinner-wrapper');

        hubMapContainer.classList.add('is-expanded');
        hubInfoTemplate.classList.add('is-shown');

        // show spinner
        spinner.classList.add('d-block');

        // make ajax call for extra hub info
        $.ajax({
            type: 'GET',
            url: '/umbraco/Api/HubApi/GetHubInfo',
            dataType: 'json',
            data: {
                HubId: hubId
            },
            success: function (data) {
                var hubInfo = data.Hubs;
                populateHubInfo(hubInfo);
                spinner.classList.remove('d-block');

                if (window.innerWidth <= breakpoints.lg) {
                    hubInfosContainer.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
                }
            }
        });
    }

    function populateHubInfo(hubInfo) {
        if (hubInfo.length > 0) {
            hubInfo.forEach(function (item) {
                var title = document.getElementById('hubTitle');
                var openHours = document.getElementById('hubOpenHours');
                var address = document.getElementById('hubAddress');
                var closeBy = document.getElementById('hubCloseBy');
                var facilities = document.getElementById('hubFacilities');

                title.innerHTML = item.Name;
                openHours.innerHTML = item.OpenHours;
                address.innerHTML = item.Address;
                closeBy.innerHTML = item.CloseBy;
                facilities.innerHTML = item.Facilities;
            });
        }
    }

    function closeHubInfos(cta) {
        var mapContainer = cta.closest('.map-container');
        var hubInfos = document.querySelectorAll('.hub-info');

        hubInfos.forEach(function (element) {
            element.classList.remove('is-shown');
        });

        mapContainer.classList.remove('is-expanded');

        if (window.innerWidth <= breakpoints.lg) {
            mapContainer.scrollIntoView({ behavior: 'smooth' });
        }
    }

    var mapStyle = [
        {
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "landscape",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#d8f1fa"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#d8f1fa"
                },
                {
                    "weight": 1
                }
            ]
        }
    ];
})();