$(document).ready(function () {
    $(document).on('click', closeNewsDropDown);
    $('.sort-dropdown-toggle').on('click', toggleNewsDropdown);
});



function closeNewsDropDown(e) {
    var target = e.target;
    if (!$(target).hasClass('sort-dropdown-toggle') && !$(target).parent().hasClass && $('.sort-dropdown-menu').hasClass('active')) {
        $('.sort-dropdown-menu').removeClass('active');
    }
}

function toggleNewsDropdown() {
    $('.sort-dropdown-menu').toggleClass('active');
}